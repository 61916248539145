import React, { useState } from 'react'
import uuid from 'react-uuid'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import Buttom from '../components/Button'
import cities from '../mock/cities.json'

const InputGroup = ({ children, label }) => {
  const { t } = useTranslation

  return (
    <div>
      <label className="flex flex-col space-y-2">
        <span>{label}</span>
        {children}
      </label>
    </div>
  )
}

const EventInputs = ({ type }) => {
  const { t } = useTranslation()
  const [city, setCity] = useState(null)
  const [violation, setViolation] = useState(null)
  const [date, setDate] = useState('')

  const eventType = type?.value ?? null
  const locations = cities
  const categoriesOfViolation = [
    {
      label: `${t('deaths', { lng: 'en' })} ${t('deaths', { lng: 'ar' })}`,
      value: 'deaths',
    },
    {
      label: `${t('injured', { lng: 'en' })} ${t('injured', { lng: 'ar' })}`,
      value: 'injuries',
    },
    {
      label: `${t('disappeared', { lng: 'en' })} ${t('disappeared', {
        lng: 'ar',
      })}`,
      value: 'disappeared',
    },
    {
      label: `${t('detained', { lng: 'en' })} ${t('detained', { lng: 'ar' })}`,
      value: 'detained',
    },
    {
      label: `${t('sgbv', { lng: 'en' })} ${t('sgbv', { lng: 'ar' })}`,
      value: 'sgbv',
    },
  ]

  switch (eventType) {
    case 'violation': {
      return (
        <>
          <InputGroup label={t('location')}>
            <Select
              isClearable
              isSearchable
              options={locations}
              className="w-full"
              value={city}
              onChange={(event) => {
                setCity(event)
              }}
            />
          </InputGroup>

          <InputGroup label={t('category of violation')}>
            <Select
              isClearable
              isSearchable
              options={categoriesOfViolation}
              className="w-full"
              value={violation}
              onChange={(event) => {
                setViolation(event)
              }}
            />
          </InputGroup>

          <InputGroup label={t('date')}>
            <input
              type="date"
              className="form-input w-full border border-green-theme"
              value={date}
              onChange={(e) => {
                setDate(e.target.value)
              }}
            />
          </InputGroup>

          <InputGroup label={t('number of victims')}>
            <input
              type="number"
              className="form-input w-full border border-green-theme"
              min="0"
            />
          </InputGroup>

          <InputGroup label={t('The group that did the violation')}>
            <input
              type="text"
              className="form-input w-full border border-green-theme"
              min="0"
            />
          </InputGroup>
        </>
      )
    }

    case 'protest': {
      return (
        <>
          <InputGroup label={t('location')}>
            <Select
              isClearable
              isSearchable
              options={locations}
              className="w-full"
              value={city}
              onChange={(event) => {
                setCity(event)
              }}
            />
          </InputGroup>

          <InputGroup label={t('date')}>
            <input
              type="date"
              className="form-input w-full border border-green-theme"
              value={date}
              onChange={(e) => {
                setDate(e.target.value)
              }}
            />
          </InputGroup>
        </>
      )
    }

    default: {
      return null
    }
  }
}

const SubmitForm = () => {
  const { t } = useTranslation()
  const [eventType, setEventType] = useState(null)

  const eventsTypes = [
    {
      label: `${t('violation', { lng: 'en' })} - ${t('violation', {
        lng: 'ar',
      })}`,
      value: 'violation',
    },
    {
      label: `${t('protest', { lng: 'en' })} - ${t('protest', {
        lng: 'ar',
      })}`,
      value: 'protest',
    },
  ]

  return (
    <form className="flex flex-col space-y-5 border border-green-theme px-5 xl:px-32 py-12 bg-white m-2 xl:m-0">
      <h1 className="lg:col-span-2 text-center text-3xl xl:text-5xl font-bold mb-5">
        {t('add to map', { lng: 'en' })} {t('add to map', { lng: 'ar' })}
      </h1>

      <InputGroup label={t('event type', { lng: 'en' })}>
        <Select
          isClearable
          options={eventsTypes}
          className="w-full"
          value={eventType}
          onChange={(event) => {
            setEventType(event)
          }}
        />
      </InputGroup>

      <EventInputs type={eventType} />

      <div className="lg:col-span-2 flex justify-center">
        <Buttom
          type="submit"
          disabled={true}
          className="disabled:opacity-50 disabled:cursor-not-allowed	"
        >
          {t('send', { lng: 'en' })} {t('send', { lng: 'ar' })}
        </Buttom>
      </div>
    </form>
  )
}

export default SubmitForm
