import React from 'react'

const Button = ({ children, className = '', ...args }) => {
  return (
    <button
      className={`flex items-center space-x-2 bg-black text-white border border-white px-12 py-3 text-lg xl:text-2xl font-bold hover:text-black hover:bg-green-theme ${className}`}
      {...args}
    >
      {children}
    </button>
  )
}

export default Button
